import { ButtonHTMLAttributes } from 'react';

import { css } from '@emotion/react';
import { darken } from 'polished';

import mixins from '@soomo/lib/styles/themes/base/mixins';
import themes, { breakpoints } from '@soomo/lib/styles/themes/index';
import { colors } from '~/themes/signin';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
	children: React.ReactNode;
}

const Button: React.FC<Props> = (props) => {
	return <button css={buttonStyles} {...props} />;
};

export default Button;

const buttonStyles = css`
	background-color: ${colors.blue};
	color: white;
	font-size: 18px;
	font-weight: 500;
	font-family: inherit;
	border-radius: 8px;
	border: 0;
	outline: 0;
	width: 125px;
	height: 63px;
	margin-right: 15px;

	&:hover {
		cursor: pointer;
		background: ${darken(0.05, colors.blue)};
	}

	&:focus {
		background: ${darken(0.1, colors.blue)};
		${mixins.webtextAccessibleFocused(themes['blue_velvet'])}
	}

	&:last-of-type {
		margin-right: 0px;
	}

	@media (max-width: ${breakpoints.small}) {
		font-size: 16px;
		width: 116px;
		height: 56px;
	}
`;
